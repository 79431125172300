import React, { useEffect } from "react";
import "../styles/free.scss"

function Free() {
  return (
    <div className="container">
        <a className="free-wrapper" href="https://www.roadmapers.com">
            <div className="free-title">НАШИ ПРОЕКТЫ</div>
            <img className="img" src={"/land/roadmapers.png"}/>
            {/* <h1 className="title">АКАДЕМИЯ</h1>
            <div className="button-wrapper">
                <div className="button primary">УЗНАТЬ БОЛЬШЕ</div>
            </div> */}
        </a>
    </div>
  );
}

export default Free;
