import React, { useEffect } from "react";
import "../styles/heading.scss"

function Heading() {
  return (
    <div className="container bg-heading">
      <div className="heading">
        <div className="heading-wrapper">
        <h1 className="title">VECTOR</h1>
        <p className="description">поиск неизбежного - сквозь иллюзию к реальности </p></div>
      </div>
    </div>
  );
}

export default Heading;
