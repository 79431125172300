//styles
import "./styles/App.scss";

import React from "react";

// components
// import Footer from "./components/Footer";

// pages
import Main from "./Pages/Main";
import Token from "./Pages/Token";

// router
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignUp from "./Pages/Auth/SignUp";
import Login from "./Pages/Auth/Login";
import CommunityPage from "./Pages/Community";
import NetworkPage from "./Pages/Network";


function App() {
  // theme references
  // const isDark = useColorScheme('dark');
  // const theme = isDark ? 'dark' : 'light';

  return (
    <div className={`App`}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/community/" element={<CommunityPage />} />
          <Route path="/token/" element={<Token />} />
          <Route path="/network/" element={<NetworkPage />} />
          {/*<Route path="/product/" element={<Product />} />*/}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
