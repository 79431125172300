import "./index.scss"
import {useEffect, useState} from "react";

function ProductOnLand({title, imgs, callback}) {

    let img = imgs[0]
    const [opacity, setOpacity] = useState(0);
    const [top, setTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            let v = window.innerHeight;
            const newOpacity = Math.max(0, scrollTop / (v*1.5)); // Adjust 500 for sensitivity
            setOpacity(newOpacity);
            console.log(newOpacity);
            // console.log(scrollTop, window.innerHeight)
            setTop(`${Math.min(v*0.9-scrollTop, v*0.10)}px`)

        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return <div className="productonland">
        <div className="productonland-wrapper">
            <div className={"productonland-cover"}>
                <div className={"productonland-title"}><h3>{title}</h3> <div className="productonland-calltoaction">
                    <div className={"productonland-button"} onClick={callback}>SOON..</div>
                </div></div>
                <img className="productonland-img"  style={{opacity: opacity, top: top}} alt="" src={img}></img>
            </div>


        </div>
    </div>
}

export default ProductOnLand;