import React, { useEffect } from "react";
import "../styles/footer.scss"

function Footer() {
  return (
    <div className="container">
        <div className="footer-wrapper">
      <h3 className="footer-logo">BUILT DIFFERENT</h3>
      <div className="footer-links">
        <a href="https://t.me/escapevector">TELEGRAM</a>
        <a href="https://instagram.com/vector.network">INSTAGRAM</a>
        <a href="https://vk.com/vector.community">VK</a>
      </div>
      <p className="rights">Copyright 2025 CC International Limited. All Rights Reserved</p>
    </div></div>
  );
}

export default Footer;
