import "./index.scss"
import Product from "../ProductOnLand"

function ProductsOnLand() {

    const navigateToMerch = () => {
        console.log("User Navigated to Merch")
    }

    return <div className="productsonland">
        <div className="container">
            <div className="productsonland-wrapper">
                <div className="productsonland-title">ПРОДУКЦИЯ СООБЩЕСТВА</div>
                <div className="productsonland-list">
                    <Product title={'МЕРЧ ОБЪЕДИНЕНИЯ'} imgs={["/land/question.png"]} callback={navigateToMerch}/>
                    {/*<Product title={''} imgs={[]}/>*/}
                </div>
            </div>
        </div>
    </div>
}

export default ProductsOnLand;