function Arrow({id=""}) {
    return <div className={'arrow-bg'}><svg id={id} width="18" height="82" viewBox="0 0 18 82" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow">
    <path d="M9 81.6603L17.6603 73L9 64.3397L0.339746 73L9 81.6603ZM7.5 0L7.5 73H10.5L10.5 0L7.5 0Z" fill="white"/>
    </svg></div>
}


export default Arrow


